import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import WaveSurfer from "wavesurfer.js";

import Icon from "components/Icon";

const AudioPlayer = ({ audioFile, name, isSelected, autoPlay = false }) => {
  const waveformRef = useRef(null);
  const waveSurfer = useRef(null);

  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [isError, setIsError] = useState(false);

  const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: isSelected ? "#6C7275" : "#F3F3F3",
    progressColor: "#0084FF",
    cursorColor: "transparent",
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: 20,
    hideScrollbar: true,
    xhr: {
      mode: "cors",
    },
    // normalize: true,
  });

  useEffect(() => {
    const options = formWaveSurferOptions(waveformRef.current);
    waveSurfer.current = WaveSurfer.create(options);

    waveSurfer.current.load(audioFile);

    waveSurfer.current.on("ready", () => {
      if (autoPlay) waveSurfer.current.play();
      setIsError(false); // Clear error state on successful load
    });

    waveSurfer.current.on("error", () => {
      console.error("Error loading audio file.");
      setIsError(true); // Set error state on failure
    });

    waveSurfer.current.on("finish", () => setIsPlaying(false));

    return () => {
      waveSurfer.current.un("error");
      waveSurfer.current.un("ready");
      waveSurfer.current.un("finish");
      waveSurfer.current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFile, autoPlay]);

  const handlePlayPause = () => {
    if (waveSurfer.current.isPlaying()) {
      waveSurfer.current.pause();
      setIsPlaying(false);
    } else {
      waveSurfer.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div
      className={twMerge(
        "w-full h-16 px-3.5 border-2 rounded-xl base2 outline-none transition-colors placeholder:text-n-4/50 bg-n-6 focus:bg-transparent flex items-center justify-between border-n-5 text-n-1 hover:bg-n-3/50 group gap-4 overflow-hidden hover:text-black",
        isSelected && "bg-n-2 text-black"
      )}
    >
      <div className="flex items-center gap-[2px] md:gap-4">
        <button
          type="button"
          onClick={handlePlayPause}
          disabled={isError} // Disable play button on error
          className={twMerge(isError && "opacity-50 cursor-not-allowed")}
        >
          <Icon
            className={twMerge(
              "md:w-8 md:h-8 w-7 h-7 p-2 mr-3 transition-colors rounded-full fill-n-7 bg-n-1 group-hover:bg-primary-2 group-hover:fill-n-1",
              isSelected && "fill-n-1 bg-primary-2"
            )}
            name={isPlaying ? "pause" : "play"}
          />
        </button>
        {name && <p>{name}</p>}
      </div>
      {isError ? (
        <p className="text-red-500">Audio not found</p>
      ) : (
        <div
          ref={waveformRef}
          className={twMerge(name ? "w-[70%]" : "w-full")}
        />
      )}
    </div>
  );
};

export default AudioPlayer;
