import { createApi } from "@reduxjs/toolkit/query/react";
import { GALLERY_END_POINTS } from "api/endPoints";
import { baseQueryWithReAuth } from "utils/baseQueryWithReauth";
import { HTTP_REQUEST_METHODS } from "utils/constants";

export const galleryApi = createApi({
  reducerPath: "galleryApi",
  baseQuery: baseQueryWithReAuth(),
  endpoints: (builder) => ({
    getExplorerData: builder.mutation({
      query: ({ page }) => ({
        url: GALLERY_END_POINTS.GET_GALLERY,
        method: HTTP_REQUEST_METHODS.GET,
        params: { page },
      }),
    }),
    getMyCollections: builder.mutation({
      query: ({ page }) => ({
        url: GALLERY_END_POINTS.GET_MY_COLLECTIONS,
        method: HTTP_REQUEST_METHODS.GET,
        params: { page },
      }),
      providesTags: ["Gallery"],
    }),

    uploadImageToCollection: builder.mutation({
      query: (payload) => ({
        url: GALLERY_END_POINTS.UPLOAD_IMAGE_TO_COLLECTION,
        method: HTTP_REQUEST_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: ["Gallery"],
    }),
    addToMyCollection: builder.mutation({
      query: ({ url }) => ({
        url: GALLERY_END_POINTS.ADD_TO_MY_COLLECTION,
        method: HTTP_REQUEST_METHODS.POST,
        body: { url },
      }),
    }),
    deleteFromMyCollection: builder.mutation({
      query: ({ imageId }) => ({
        url: GALLERY_END_POINTS.DELETE_FROM_MY_COLLECTION,
        method: HTTP_REQUEST_METHODS.DELETE,
        body: { image_id: imageId },
      }),
    }),
    exploreMediaSearch: builder.mutation({
      query: ({ keyword }) => ({
        url: GALLERY_END_POINTS.GET_GALLERY,
        method: HTTP_REQUEST_METHODS.POST,
        body: { keyword },
      }),
    }),
    searchMyCollections: builder.mutation({
      query: ({ keyword }) => ({
        url: GALLERY_END_POINTS.SEARCH_MY_COLLECTIONS,
        method: HTTP_REQUEST_METHODS.POST,
        body: { keyword },
      }),
    }),
    addAudioToCollection: builder.mutation({
      query: (data) => ({
        url: GALLERY_END_POINTS.ADD_AUDIO_TO_COLLECTION,
        method: HTTP_REQUEST_METHODS.POST,
        body: data,
      }),
    }),
    getAudioCollections: builder.mutation({
      query: ({ page }) => ({
        url: GALLERY_END_POINTS.GET_AUDIO_COLLECTIONS,
        method: HTTP_REQUEST_METHODS.GET,
        params: { page },
      }),
    }),
    deleteAudioFromCollection: builder.mutation({
      query: ({ audioId }) => ({
        url: GALLERY_END_POINTS.DELETE_AUDIO_FROM_COLLECTION,
        method: HTTP_REQUEST_METHODS.DELETE,
        body: { id: audioId },
      }),
    }),
    searchMyAudioCollections: builder.mutation({
      query: ({ keyword }) => ({
        url: GALLERY_END_POINTS.SEARCH_MY_AUDIO_COLLECTIONS,
        method: HTTP_REQUEST_METHODS.POST,
        body: { search_text: keyword },
      }),
    }),
  }),
});

export const {
  useGetExplorerDataMutation,
  useGetMyCollectionsMutation,
  useAddToMyCollectionMutation,
  useUploadImageToCollectionMutation,
  useExploreMediaSearchMutation,
  useDeleteFromMyCollectionMutation,
  useSearchMyCollectionsMutation,
  useAddAudioToCollectionMutation,
  useGetAudioCollectionsMutation,
  useDeleteAudioFromCollectionMutation,
  useSearchMyAudioCollectionsMutation,
} = galleryApi;
