export const END_POINTS = {
  // VideoMaker API Endpoints
  GENERATE_SCRIPT: "/videogenerator/generateScript/",
  ADD_SCRIPT_PART: "/videogenerator/addscriptpart/",
  UPDATE_SCRIPT_PARTS_ORDER_NO:
    "/videogenerator/script-image-video/bulk-update-no/",
  DELETE_SCRIPT_PART: "/videogenerator/addscriptpart/",
  ADD_SUBTITLE: "/videogenerator/addsubtitle/",
  CHANGE_VOICE: "/videogenerator/changevoice/",
  GENERATE_VIDEO: "/videogenerator/createvideo/",
  GENERATE_PURE_READ: "/videogenerator/getpureread/",
  UPDATE_SCRIPT_PRE_RENDER_OPTIONS: "/videogenerator/generateScript/",
};

export const EDIT_VIDEO_END_POINTS = {
  TRIM_VIDEO: "/videogenerator/trimvideo/",
  ADD_LOGO: "/videogenerator/uploadlogo/",
  EDIT_VIDEO: "/videogenerator/editvideo/",
  GET_EDIT_VIDEO_DATA: "/videogenerator/editvideo",
  GENERATE_INTRO_IMAGE: "/videogenerator/createimageintroscenes/",
  GENERATE_OUTRO_IMAGE: "/videogenerator/createimageclosescenes/",
};

export const GALLERY_END_POINTS = {
  GET_GALLERY: "/videogenerator/showallimages/",
  GET_MY_COLLECTIONS: "/videogenerator/upload/",
  SEARCH_MY_COLLECTIONS: "/videogenerator/searchmygallery/",
  DELETE_FROM_MY_COLLECTION: "/videogenerator/searchmygallery/",
  ADD_TO_MY_COLLECTION: "/videogenerator/addtomygallery/",
  UPLOAD_IMAGE_TO_COLLECTION: "/videogenerator/upload/",
  DELETE_IMAGE_FROM_COLLECTION: "/gallery/deleteimage/",
  ADD_AUDIO_TO_COLLECTION: "/videogenerator/uploadbackgroundmusiclist/",
  DELETE_AUDIO_FROM_COLLECTION: "/videogenerator/uploadbackgroundmusiclist/",
  GET_AUDIO_COLLECTIONS: "/videogenerator/uploadbackgroundmusiclist/",
  SEARCH_MY_AUDIO_COLLECTIONS: "/videogenerator/search_bg_music/",
};

export const VIDEO_LIBRARY_END_POINTS = {
  PUBLISH_VIDEO: "/videogenerator/publishvideo/",
  CHECK_PUBLISH_STATUS: "/videogenerator/CheckPublishVideo/",
};

export const MISC_END_POINTS = {
  SAVE_EDITED_IMAGE: "/videogenerator/editedimage/",
  GET_MOVIE_PY_FONT_FAMILY: "/videogenerator/addfonts/",
  GET_MOVIE_PY_FONT_COLOR: "/videogenerator/addfontcolor/",
  GET_MOVIE_PY_FONT_SIZE: "/videogenerator/addfontsize/",
  GET_BACKGROUND_MUSIC: "/videogenerator/getBackgroundmusiclist/",
};
