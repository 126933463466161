import { toast } from "react-hot-toast";
import { loggedOut } from "store/AuthSlice";

// import { logout } from "store/AuthSlice";

export const successToast = (data, message) => {
  toast.success(data?.message || message);
};

export const errorToast = ({
  dispatch,
  error,
  message,
  duration = 3000,
  style = {},
  skipLogout = false,
}) => {
  // if user is not authenticated then logout the user and redirect to login page
  if (error?.response?.status === 401 && dispatch && !skipLogout) {
    toast.error("You have been logged out. Please login again.");
    setTimeout(() => {
      dispatch(loggedOut());
    }, 1000);
    return;
  }

  toast.error(
    error?.response?.data?.error ||
      error?.data?.error ||
      message ||
      "Something went wrong, please try again later.",
    { duration: duration, style: { ...style } }
  );
};

export const isObjectEmpty = (obj) => {
  // Check if the variable is an object and not null or undefined
  return (
    typeof obj === "object" &&
    obj?.constructor === Object &&
    Object.keys(obj).length === 0
  );
};

// Groups an array of objects by a specified key.
export const groupBy = (items = [], key = "") => {
  // Validate parameters
  if (!Array.isArray(items) || typeof key !== "string") {
    // throw new Error("Invalid parameters. Please provide an array and a key.");
    return {};
  }

  // Use reduce to iterate over the array and construct the grouped object
  return items.reduce((groupedItems, currentItem) => {
    // Extract the value of the specified key from the current object
    const keyValue = currentItem[key];

    // Construct a new object with the existing properties and the updated array
    return {
      ...groupedItems,
      [keyValue]: [...(groupedItems[keyValue] || []), currentItem],
    };
  }, {});
};

export const groupByDate = ([...srcArray]) => {
  return srcArray.reduce((groupedData, item) => {
    const { date = "" } = item;
    const newDate = new Date(date);

    if (isNaN(newDate)) {
      // skip the item or assign it to a default date
      return groupedData;
    }

    const dateString = newDate.toDateString();

    if (!groupedData.hasOwnProperty(dateString)) {
      groupedData[dateString] = [];
    }

    groupedData[dateString].push(item);
    return groupedData;
  }, {});
};

// Function to convert the API response to the required format for the card component and the audio player
const transformVideoMakerData = ({ apiResponse, includeScripId = false }) => {
  if (!apiResponse) return [];
  const scriptId = apiResponse.script_id;

  const imageVideoLink = apiResponse.image_video_links;

  const data = imageVideoLink.map((item, index) => {
    const src = item.urls
      .map((url, id) => {
        if (!url) return null;
        return {
          id: id + 1,
          alt: item.term,
          src: url,
          type: url.endsWith(".mp4") ? "video" : "image",
        };
      })
      .filter(Boolean);

    return {
      id: index + 1,
      termId: item.id,
      src,
      title: item.term,
      timeline: item.time_stamp,
      duration: item.duration,
      term: item.term,
      searchTerm: item.search_term,
      imageSearchTerm: item.dalle_search_term,
      animation: "",
      transition: "",
      audioSrc: item?.audio_file,
      subTitle: item.subtitle,
      trimFlag: item.trim_flag,
    };
  });

  // Splitting the content into lines based on double new line or single new line characters (\n\n or \n) to get the title and timeline

  // // If the content is not available, return an empty array as the data
  // const contentLines = apiResponse?.content
  //   ? apiResponse?.content?.split(/\n\n|\n/)
  //   : [];

  // const data = contentLines
  //   .map((line, index) => {
  //     const timestampMatch = line.match(/\[(.*?)\]/);
  //     const timeline = timestampMatch ? timestampMatch[1] : "";

  //     // Adjusting the timeline format if necessary
  //     let adjustedTimeline = timeline;
  //     if (!/^\d{2}:\d{2} - \d{2}:\d{2}$/.test(timeline)) {
  //       const lastTime = timeline.split(" - ")[1];
  //       const adjustedLastTime =
  //         lastTime?.length === 2 ? `00:${lastTime}` : lastTime;
  //       adjustedTimeline = timeline.replace(lastTime, adjustedLastTime);
  //     }

  //     // Getting the title by removing the timestamp from the line
  //     const title = line.replace(/\[.*?\]:/g, "").trim();

  //     // Getting the image/video link based on the index
  //     const imageVideoLink = apiResponse.image_video_links[index];

  //     if (!imageVideoLink) {
  //       // If imageVideoLink is undefined, return empty array for src
  //       return {
  //         id: index + 1,
  //         src: [],
  //         title,
  //         timeline: "",
  //         term: "",
  //         animation: "",
  //         transition: "",
  //       };
  //     }

  //     // Mapping the urls to get the src and type
  //     const src = imageVideoLink.urls.map((url, id) => {
  //       return {
  //         id: id + 1,
  //         alt: imageVideoLink.term,
  //         src: url,
  //         type: url.endsWith(".mp4") ? "video" : "image",
  //       };
  //     });

  //     // Returning the object with the id, title, timeline, src and term
  //     return {
  //       id: index + 1,
  //       termId: imageVideoLink.id,
  //       src,
  //       title,
  //       timeline: adjustedTimeline,
  //       term: imageVideoLink.term,
  //       animation: "",
  //       transition: "",
  //       audioSrc: imageVideoLink?.audio_file,
  //     };
  //   })
  //   .filter(Boolean);

  // Returning the data with the scriptId if includeScripId is true or just the data if it's false
  return includeScripId ? { scriptId, data } : data;
};

// Function to find option from options array by specified property
const findOptionByPropertyValue = (options, value, property) => {
  const option = options?.find((item) => item[property] === value);

  // if option is not available, return a null value
  return option || null;
};

export { transformVideoMakerData, findOptionByPropertyValue };
